<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editRole')  + ' (' + this.payload.id + ')' : $t('common.form.title.createRole')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.role"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-card class="mt-3">
                    <template v-slot:header>
                        {{ $t('settings.roles.label.permissions') }}
                        <b-form-checkbox :value="1"
                                         :unchecked-value="0"
                                         @change="(val)=>{selectAll(getPermissions(),val)}"
                        >
                        </b-form-checkbox>
                    </template>
                    <b-card-text>
                        <b-row
                        >
                            <b-col cols="12"
                                   v-for="(permissions,index) in sortedPermissions"
                                   :key="index"
                            >
                                <b-form-group>
                                    <b-form-checkbox :value="1"
                                                     :unchecked-value="0"
                                                     @change="(val)=>{selectAllValues(index, val)}"
                                    >
                                        {{ index }}
                                    </b-form-checkbox>
                                    <multiselect v-model="form.permissions[index]"
                                                 :options="permissions"
                                                 :multiple="true"
                                                 select-label=""
                                    ></multiselect>
                                    <template v-if="index=='customers'">
                                        <b-form-group :label="$t(labelPrefix+'customer_tabs')">
                                            <multiselect v-model="form.settings[index]"
                                                         :options="customerTabs"
                                                         :multiple="true"
                                                         select-label=""
                                            ></multiselect>
                                        </b-form-group>
                                    </template>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Role-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                role: '',
                permissions: {},
                settings: {},
            },
            form: {},
            labelPrefix: 'settings.roles.label.',
            customerTabs: [
                'events', 'general', 'contact', 'tax', 'social', 'conditions', 'groups', 'other', 'contact_persons',
                'offerings', 'contracts', 'documents', 'clients', 'emails', 'customer_templates'
            ],
        }
    },
    methods: {
        ...mapGetters('Roles', ['getRole']),
        ...mapGetters('Modules', ['getPermissions']),
        shown() {
            const role = this.$store.dispatch('Roles/fetchRole', this.payload.id)
            const modules = this.$store.dispatch('Modules/fetchModules')

            Promise.all([role, modules])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getRole()))
                    }

                    // console.log(typeof this.form.permissions, JSON.parse(JSON.stringify(this.getRole())))
                    if (Object.keys(this.form.permissions).length == 0) {
                        this.form.permissions = {}
                    }
                    if (!this.form.settings || Object.keys(this.form.settings).length == 0) {
                        this.form.settings = {}
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        selectAll(permissions, val) {
            Object.keys(permissions).forEach(index => {
                this.selectAllValues(index, val)
            })
        },
        selectAllValues(index, val) {
            if (val) {
                this.$set(this.form.permissions, index, this.getPermissions()[index])
            } else {
                this.$set(this.form.permissions, index, [])

            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Roles/saveRole', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    computed: {
        sortedPermissions() {
            return Object.keys(this.getPermissions())
                .sort()
                .reduce((result, key) => {
                    result[key] = this.getPermissions()[key];
                    return result;
                }, {});
        },
    },
}
</script>